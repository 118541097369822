import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { isStaging } from '../utils/Utils';

type Props = {
  url: string;
  type: string;
};

const HeadPreloadLink = ({ url, type }: Props): JSX.Element => {
  return <link rel="preload" href={url} as={type} crossOrigin="" />;
};

const IndexHead = (): JSX.Element => {
  return (
    <>
      <Script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=kvika-onboarding" />
      <Head>
        {isStaging() && <meta name="robots" content="noindex" />}
        <title>Kvika</title>
        <link rel="icon" href="/favicon.ico" />

        <HeadPreloadLink url="/fonts/ACaslonPro-Bold.otf" type="font" />
        <HeadPreloadLink url="/fonts/ACaslonPro-Italic.otf" type="font" />
        <HeadPreloadLink url="/fonts/ACaslonPro-Regular.otf" type="font" />
        <HeadPreloadLink url="/fonts/ACaslonPro-Semibold.otf" type="font" />
        <HeadPreloadLink url="/fonts/AkzidenzGroteskPro-Regular.otf" type="font" />
        <HeadPreloadLink url="/fonts/AkzidenzGroteskPro-Bold.otf" type="font" />
        <HeadPreloadLink url="/fonts/AkzidenzGroteskPro-It.otf" type="font" />
        <HeadPreloadLink url="/fonts/AkzidenzGroteskPro-Light.otf" type="font" />
        <HeadPreloadLink url="/fonts/AkzidenzGroteskPro-Md.otf" type="font" />
      </Head>
    </>
  );
};

export default IndexHead;
